import React, {
  memo,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import currency from 'currency.js';
import { ThemeProvider } from '@tmap/mmm-style-guide/src/Theming';
import truncateText from '@tmap/mmm-core/truncateText';
import { Box } from '@tmap/mmm-style-guide/src/Box';
import { Grid } from '@tmap/mmm-style-guide/src/Grid';
import { Typography } from '@tmap/mmm-style-guide/src/Typography';
import { CircularProgress } from '@tmap/mmm-style-guide/src/Progress';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@tmap/mmm-style-guide/src/Icon';
import { IconButton } from '@tmap/mmm-style-guide/src/IconButton';
import { styled } from '@tmap/mmm-style-guide/src/styled';
import stopEvent from '@tmap/mmm-style-guide/src/util/stopEvent';
import locationLabelText from '@tmap/mmm-core/locationLabelText';
import LinkCard from '../linkCard';
import { LIGHT_THEME } from '../../lib/themes';
import isCustomerIncentive from '../../lib/incentive/isCustomerIncentive';
import ResponsivePicture from '../responsivePicture';
import getCommonProps from './shared/getCommonProps';
import CommunityImpressionWrapper from './shared/impressionWrapper';

const SwiperStyles = styled(Box)(({ theme }) => ({
  position: 'relative',
  zIndex: 0,
  borderRadius: '8px',
  background: theme.palette.secondary.main,
  overflow: 'hidden',
  '& .swiper': {
    borderRadius: 'inherit',
  },
}));

const ProgramValueChip = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  borderRadius: '4px',
  padding: theme.spacing(0.5, 1),
  background: theme.palette.common.white,
  boxShadow: '1px 1px 3px 0px rgba(0, 0, 0, 0.1)',
}));

const ImageWrapperHover = styled(LinkCard.ImageWrapper)(({ theme }) => ({
  '.desktopHover': {
    display: 'none',
  },
  [theme.breakpoints.up('md')]: {
    '.desktopHover': {
      display: 'flex',
      opacity: 0,
      transition: 'opacity cubic-bezier(0.25, 0, 0, 1) 1s',
    },
    '&:hover .desktopHover': {
      opacity: 1,
    },
  },
}));

const IconButtonInverted = styled(IconButton)(({ theme }) => ({
  pointerEvents: 'all',
  background: 'rgba(255, 255, 255, 0.50)',
  margin: theme.spacing(1.5),
  color: theme.palette.inverted.contrastText,
  '&:hover': {
    background: 'rgba(255, 255, 255, 0.65)',
  },
}));

const DotsGrid = styled(Grid, {
  shouldForwardProp: (p) => p !== 'sizeMod',
})(({ sizeMod }) => ({
  paddingLeft: sizeMod > -1 ? undefined : '0!important',
  transition: 'padding 0.25s linear',
}));

const DotsDot = styled(Box, {
  shouldForwardProp: (p) => !['visible', 'baseOpacity'].includes(p),
})(({ theme, visible, baseOpacity }) => ({
  borderRadius: '16px',
  opacity: visible ? baseOpacity : 0,
  background: theme.palette.common.white,
  transition: 'width 0.25s linear, height 0.25s linear, opacity 0.15s linear',
}));

function PagingDots(props) {
  const { current = 1, slideEntities = [] } = props;
  return (
    <Grid container spacing={0.75} justifyContent='center' alignItems='center' mb={1}>
      {slideEntities.map((entity, i) => {
        const pos = i + 1;
        const selected = Boolean(current === pos);
        const isMiddle = (current > 1) && (current < slideEntities.length - 2);
        const rightDots = (isMiddle ? 4 : 5) - Math.abs(pos - current);
        const leftDots = (isMiddle ? 3 : 4) - Math.abs(pos - current);
        const sizeMod = pos > current
          ? rightDots
          : leftDots;
        const dotSize = pos > current
          ? Math.max(Math.min(7, 1 + (sizeMod * 2)), 3)
          : Math.max(Math.min(7, 5 + sizeMod), 3);
        const visible = sizeMod > 0;
        const pxSize = `${visible ? dotSize : 0}px`;
        const baseOpacity = (selected ? 1 : 0.5);
        if (sizeMod < -5) return null;
        return (
          <DotsGrid item key={entity._key} sizeMod={sizeMod}>
            <DotsDot
              width={pxSize}
              height={pxSize}
              visible={visible}
              baseOpacity={baseOpacity}
            />
          </DotsGrid>
        );
      })}
    </Grid>
  );
}

const StyledProgress = styled(CircularProgress)(({ theme }) => ({
  color: theme.palette.common.white,
}));

const OptimizedImage = memo((props) => {
  const {
    image,
    width,
    height,
    shouldLazyLoad,
    lazyProps,
    altText,
    activeSlide,
    slideIndex,
    preload,
  } = props;
  return (
    <LinkCard.Image
      shouldLazyLoad={shouldLazyLoad}
      lazyProps={{ ...lazyProps, height, width }}
    >
      {slideIndex === 0 || activeSlide >= slideIndex - 1 ? (
        <ResponsivePicture
          image={{ ...image, alt: altText }}
          width={width}
          height={height}
          preload={preload}
          xs={1.5}
          sm={2}
          md={1.5}
          lg={1}
          style={{
            width: '100%',
            objectFit: 'cover',
            borderRadius: 'inherit',
          }}
        />
      ) : (
        <Box width={width} height={height} display='flex' alignItems='center' justifyContent='center'>
          <StyledProgress />
        </Box>
      )}
    </LinkCard.Image>
  );
});

const CustomOverlays = styled(LinkCard.Overlays)(({ theme }) => ({
  pointerEvents: 'none',
  padding: theme.spacing(2),
  borderRadius: '8px',
  background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0.5) 60%)',
  '& .MuiButtonBase-root': {
    pointerEvents: 'all',
  },
}));

function GalleryCommunityCard(props) {
  const {
    community,
    shouldLazyLoad,
    lazyProps,
    placementId,
    pageId,
    showProgramValue = false,
    disableSwiper = false,
    highRes = false,
    preload = false,
  } = props;

  const { communityUrl, altText } = getCommonProps(community);

  const favoriteTarget = useRef();

  const [current, setCurrent] = useState(0);

  const width = highRes ? 900 : 328;
  const height = 416;

  const swiperRef = useRef(null);
  const renderCustomSwiperPager = (swiper, sCurrent) => {
    setCurrent(sCurrent);
    return '';
  };

  const handlePrev = (e) => {
    stopEvent(e);
    swiperRef.current.swiper.slidePrev();
  };

  const handleNext = (e) => {
    stopEvent(e);
    swiperRef.current.swiper.slideNext();
  };

  const activePrograms = community.offers.filter((program) => (
    program.applicationUrl || isCustomerIncentive(program)
  ));

  const galleryImages = community?.galleryImages.filter((image) => image._type === 'standardImage');
  const galleryMode = !disableSwiper && galleryImages.length > 1;
  const activeSlide = swiperRef?.current?.swiper?.realIndex || 0;

  // lazy init the swiper
  const [loadSwiper, setLoadSwiper] = useState(false);
  useEffect(() => {
    if (galleryMode) {
      const enableSwiper = () => setLoadSwiper(true);
      if (requestAnimationFrame) {
        requestAnimationFrame(enableSwiper);
      } else {
        setTimeout(enableSwiper, 0);
      }
    }
  }, [galleryMode]);

  return (
    <CommunityImpressionWrapper
      community={community}
      placementId={placementId}
      pageId={pageId}
      favoriteTarget={favoriteTarget}
    >
      <LinkCard href={communityUrl} alt={altText}>
        <ImageWrapperHover>
          <CustomOverlays>
            {galleryMode && (
              <Box className='desktopHover' position='absolute' top='0' left='0' width='100%' height='100%' alignItems='flex-end'>
                <Grid item xs>
                  <IconButtonInverted size='small' onClick={handlePrev}>
                    <KeyboardArrowLeft />
                  </IconButtonInverted>
                </Grid>
                <Grid item>
                  <IconButtonInverted size='small' onClick={handleNext}>
                    <KeyboardArrowRight />
                  </IconButtonInverted>
                </Grid>
              </Box>
            )}
            <Grid item xs={12}>
              <Grid container alignItems='center'>
                {showProgramValue && (
                  <Grid item>
                    <ThemeProvider theme={LIGHT_THEME}>
                      <ProgramValueChip>
                        <Typography component='span' fontSize='14px' color='textPrimary' fontWeight={600} mr={0.625}>
                          {currency(
                            community?.offers?.[0]?.totalValue || 0,
                            { precision: 0 },
                          ).format()}
                        </Typography>
                        <Typography component='span' fontSize='10px' color='textSecondary'>
                          in incentives
                        </Typography>
                      </ProgramValueChip>
                    </ThemeProvider>
                  </Grid>
                )}
                <LinkCard.FavoriteButton ref={favoriteTarget} entity={community} dim />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Box display='flex' flexDirection='column' height='100%' justifyContent='flex-end'>
                <Typography color='textPrimary' fontSize='22px' fontWeight={800}>
                  {locationLabelText(
                    community.locationText,
                    community.regionText,
                    community?.locationGeo?.state_abbreviation,
                  )}
                </Typography>
                <Typography color='textPrimary' fontSize='14px'>
                  {activePrograms.length} Active Program{activePrograms.length === 1 ? '' : 's'}
                </Typography>
                <Typography color='textPrimary' fontSize='14px' mt={2} mb={4}>
                  {truncateText(community?.blockDescription || '', 115)[0]}
                </Typography>
                {galleryMode && (
                  <PagingDots current={current} slideEntities={galleryImages} />
                )}
              </Box>
            </Grid>
          </CustomOverlays>
          <SwiperStyles>
            {loadSwiper ? (
              <Swiper
                ref={swiperRef}
                slidesPerView={1}
                modules={[Navigation, Pagination]}
                pagination={{ type: 'custom', renderCustom: renderCustomSwiperPager }}
              >
                {galleryImages.map((image, i) => (
                  (i !== 0 && activeSlide < i - 2) ? null : (
                    <SwiperSlide key={image._key}>
                      <OptimizedImage
                        image={image}
                        width={width}
                        height={height}
                        altText={altText}
                        shouldLazyLoad={shouldLazyLoad || i > 2}
                        lazyProps={lazyProps}
                        activeSlide={activeSlide}
                        slideIndex={i}
                        preload={preload && i === 0}
                      />
                    </SwiperSlide>
                  )
                ))}
              </Swiper>
            ) : (
              <OptimizedImage
                image={galleryImages[0]}
                width={width}
                height={height}
                altText={altText}
                shouldLazyLoad={shouldLazyLoad}
                lazyProps={lazyProps}
                activeSlide={activeSlide}
                slideIndex={0}
                preload={preload}
              />
            )}
          </SwiperStyles>
        </ImageWrapperHover>
      </LinkCard>
    </CommunityImpressionWrapper>
  );
}

export default GalleryCommunityCard;
